<template>
  <v-main>
    <CustomLayout>

      <template slot="side">
        <CustomLayoutItemSelector title="Prozessketten" create-item-text="Neue Prozesskette"
                                  :items="processChains" label-field="name"></CustomLayoutItemSelector>
      </template>

      <!-- main area -->
      <v-container class="pt-4">
        <div v-for="(processChain, index) in processChains" :key="processChain.id"
             v-if="processChain.id === selectedProcessChainId" class="px-4">

          <!-- headline -->
          <div class="d-flex align-baseline">
            <div v-text="processChain.name ? processChain.name : '<unbenannte Prozesskette>'" class="text-h5"></div>
            <v-spacer></v-spacer>
            <v-btn v-if="showMemberView" outlined @click="showMemberView = false">
              <v-icon left>mdi-shape-outline</v-icon>
              Prozesskette
            </v-btn>
            <v-btn v-else outlined @click="showMemberView = true">
              <v-icon left>mdi-table</v-icon>
              Nutzerübersicht
            </v-btn>
          </div>


          <div v-if="!showMemberView">
            <v-card class="mt-3">
              <v-card-title>
                <div class="d-flex flex-row align-baseline">
                  <div>Bedingungen</div>
                  <v-select :items="conditionLogic" outlined hide-details dense class="ml-3" style="max-width: 300px;"
                            item-text="label" item-value="id"
                            v-model="processChain['conditionLogic']" @change="itemChanged(processChain)">
                    <template v-slot:selection="{ item, index }">
                      <div class="font-weight-regular black--text">{{ item.label }}</div>
                    </template>
                  </v-select>
                </div>
              </v-card-title>

              <v-card-text class="mt-1">
                <div v-for="(condition, index) in processChain['conditions']" :key="index">
                  <div class="d-flex flex-row align-center mb-3">
                    <div class="text-body-1 grey--text text--darken-2">{{ "#" + (index + 1) }}</div>

                    <v-select :items="modifiers" outlined hide-details class="ml-3" style="max-width: 150px;"
                              item-text="label" item-value="id" label="Modifikator" dense
                              v-model="condition['modifier']" @change="itemChanged(processChain)"></v-select>

                    <v-select :items="conditionTypes" outlined hide-details class="ml-3" style="max-width: 200px;"
                              item-text="label" item-value="id" label="Typ" dense
                              v-model="condition['type']" @change="itemChanged(processChain)"></v-select>

                    <v-text-field outlined hide-details class="ml-3" style="max-width: 200px" dense
                                  v-model="condition['userActionEndpoint']" label="Endpunkt"
                                  @input="itemChanged(processChain)"></v-text-field>

                    <v-text-field outlined hide-details class="ml-3" style="max-width: 200px" dense
                                  v-model="condition['userAction']" label="Aktion"
                                  @input="itemChanged(processChain)"></v-text-field>

                    <v-text-field outlined hide-details class="ml-3" style="max-width: 100px" dense
                                  v-model="condition['entityId']" label="Entity ID"
                                  @input="itemChanged(processChain)"></v-text-field>

                    <v-text-field v-if="condition['type'] === 2"
                        outlined hide-details class="ml-3" style="max-width: 100px" dense
                                  v-model="condition['delayHours']" label="Delay (h)"
                                  @input="itemChanged(processChain)"></v-text-field>

                    <v-btn icon color="red" class="ml-2"
                           @click="processChain['conditions'].splice(index, 1); itemChanged(processChain)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>

                  </div>
                </div>

                <v-btn outlined small class="transform-none mt-2" @click="addCondition(processChain)">
                  <v-icon left small>mdi-plus</v-icon>
                  <span>Bedingung hinzufügen</span>
                </v-btn>
              </v-card-text>

            </v-card>
            <v-card class="mt-3">
              <v-card-title class="d-flex align-start">
                Prozessschritte
                <v-switch
                    v-model="processChain['enableStepExecution']"
                    :label="processChain['enableStepExecution'] ? 'Ausführung aktiviert' : 'Ausführung deaktiviert'"
                    hide-details class="mt-0 ml-4"
                    @change="itemChanged(processChain)">
                ></v-switch>
              </v-card-title>
              <v-card-text>

                <div v-for="(step, index) in processChain['steps']" :key="index">
                  <div class="d-flex flex-row align-center mb-2">
                    <div class="text-body-1">{{ "#" + (index + 1) }}</div>

                    <div class="text-body-1 ml-2">Bezeichner (Global):</div>
                    <v-text-field outlined hide-details dense class="ml-2" style="max-width: 300px"
                                  v-model="step['name']"
                                  @input="itemChanged(processChain)"></v-text-field>

                    <div class="text-body-1 ml-4">Verzögerung:</div>
                    <v-text-field outlined hide-details dense class="ml-2" style="max-width: 70px"
                                  v-model="step['delayValue']"
                                  @input="itemChanged(processChain)"></v-text-field>
                    <v-select :items="delayUnits" outlined hide-details dense class="ml-2" style="max-width: 120px;"
                              item-text="label" item-value="id"
                              v-model="step['delayUnit']" @change="itemChanged(processChain)"></v-select>

                    <div class="text-body-1 ml-4">Aktion:</div>
                    <v-select :items="stepTypes" outlined hide-details dense class="ml-2" style="max-width: 200px;"
                              item-text="label" item-value="id"
                              v-model="step['action']" @change="itemChanged(processChain)"></v-select>

                    <div class="text-body-1 ml-4">Inhalt:</div>
                    <v-text-field outlined hide-details dense class="ml-2" style="max-width: 200px"
                                  v-model="step['content']"
                                  @input="itemChanged(processChain)"></v-text-field>

                    <v-btn icon color="red" class="ml-2"
                           @click="processChain['steps'].splice(index, 1); itemChanged(processChain)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>

                  </div>
                </div>

                <v-btn outlined small class="transform-none mt-2" @click="addStep(processChain)">
                  <v-icon left small>mdi-plus</v-icon>
                  <span>Prozessschritt hinzufügen</span>
                </v-btn>
              </v-card-text>
            </v-card>
          </div>
          <div v-else>
            <v-card class="mt-3">
              <v-card-title class="pb-0">Nutzerübersicht</v-card-title>
              <v-card-text class="pa-0">
                <ProcessChainMemberView :process-chain-id="processChain.id"></ProcessChainMemberView>
              </v-card-text>
            </v-card>
          </div>

        </div>
      </v-container>


    </CustomLayout>
  </v-main>
</template>

<script>
import CustomLayout from "@/components/reusable/CustomLayout";
import CustomLayoutItemSelector from "@/components/reusable/CustomLayoutItemSelector";

import mitt from "mitt";
import _ from "lodash";
import ProcessChainMemberView from "@/components/ProcessChainMemberView";

window.mitt = window.mitt || new mitt();

export default {
  name: "ProcessChains",
  components: { ProcessChainMemberView, CustomLayoutItemSelector, CustomLayout },
  data: () => ({
    processChains: [],
    selectedProcessChainId: undefined,
    updatesToPush: [],
    showMemberView: false,
    // static fields
    conditionLogic: [
      { id: 0, label: "eine muss erfüllt sein" },
      { id: 1, label: "alle müssen erfüllt sein" }
    ],
    modifiers: [
      { id: 0, label: "-" },
      { id: 1, label: "NICHT" },
      { id: 2, label: "inaktiv" }
    ],
    conditionTypes: [
      { id: 1, label: "user-action" },
      { id: 2, label: "user-action-delay" }
    ],
    delayUnits: [
      { id: 0, label: "Minuten" },
      { id: 1, label: "Stunden" },
      { id: 2, label: "Tage" },
      { id: 3, label: "Wochen" },
      { id: 4, label: "Monate" }
    ],
    stepTypes: [
      { id: 1, label: "E-Mail versenden" },
      { id: 2, label: "Label hinzufügen" },
      { id: 3, label: "Account löschen" }
    ]
  }),
  mounted() {
    window.mitt.on("customLayoutCreateItem-Prozessketten", this.createProcessChain);
    window.mitt.on("customLayoutSelectItem-Prozessketten", this.selectProcessChain);

    this.fetchProcessChains();
  },
  beforeDestroy() {
    window.mitt.off("customLayoutCreateItem-Prozessketten", this.createProcessChain);
    window.mitt.off("customLayoutSelectItem-Prozessketten", this.selectProcessChain);
  },
  methods: {
    fetchProcessChains() {
      this.$store.dispatch("getRequestPromise", ["process-chains"]).then(data => {
        this.processChains = data;
      });
    },
    createProcessChain: function() {
      this.$store.dispatch("postRequest", ["process-chains", "", this.processChainCreatedCallback]);
    },
    processChainCreatedCallback: function(newItem) {
      let updatedItemList = this.processChains;
      updatedItemList.unshift(newItem); // add new template to the beginning of the list
      this.processChains = [];
      this.$nextTick(() => { // wait a tick to make the make the ui realize that the list is empty
        this.processChains = updatedItemList;
      });
    },
    selectProcessChain: function(id) {
      this.selectedProcessChainId = id;
    },
    addCondition(processChain) {
      processChain["conditions"].push({
        modifier: 0,
        type: 0,
        userActionEndpoint: "",
        userAction: "",
        entityId: -1
      });
      this.itemChanged(processChain);
    },
    addStep(processChain) {
      processChain["steps"].push({
        name: "",
        delayUnit: 2,
        delayValue: 1,
        action: 0,
        content: ""
      });
      this.itemChanged(processChain);
    },
    itemChanged(item) {
      let index = this.updatesToPush.indexOf(item);
      if (index === -1) {
        this.updatesToPush.push(item);
      }
      this.schedulePush();
    },
    schedulePush: _.debounce(function() {
      this.$store.dispatch("putRequest", ["process-chains", this.updatesToPush]);
      this.updatesToPush = [];
    }, 500)
  }
};
</script>

<style scoped>

</style>