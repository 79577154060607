<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="white-space: nowrap">Nutzer</th>
          <th v-for="index in numberOfConditions" :key="index" style="white-space: nowrap">
            <div v-if="index < numberOfConditions" class="px-2">{{ "Bed. " + index }}</div>
            <div v-else>Ergebnis</div>
          </th>
          <th style="white-space: nowrap; text-align: center">
            Datum
          </th>
          <th v-for="name in stepNames" :key="name" style="white-space: nowrap; text-align: center">
            <div style="max-width: 70px; overflow: hidden; text-overflow: ellipsis">{{ name }}</div>
          </th>
          <th style="width: 99%"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="member in members" :key="member.userId">
          <td style="white-space: nowrap;">
            <div class="d-flex flex-column justify-center text-body-1" style="height: 56px">
              <span v-text="member['fullName']" class="font-weight-medium"></span>
              <span v-text="member['email']"></span>
            </div>
          </td>
          <!-- border if not last element -->
          <td :style="'white-space: nowrap; text-align: center; ' + (result ? 'background-color: #a8f0ab; ' : '') + 'border-right2: 1px solid #737373'"
              v-for="(result, index) in member['conditionResults']" :key="index">
            <!-- (index < (numberOfConditions - 1) ? ' border-right: 1px solid #737373' : '') -->
            <v-icon v-if="result" color="black">mdi-check</v-icon>
            <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
          </td>
          <td style="white-space2: nowrap; text-align: center">
            {{ member["firstTimeMet"] | formatDateShort }}
            {{ member["firstTimeMet"] | formatTime }}
          </td>
          <td v-for="(result, index) in member['executedSteps']" :key="index"
              :style="'text-align: center; ' + (result ? 'background-color: #a8f0ab; ' : '') + 'border-right2: 1px solid #737373'"
          >
            <!-- (index < (numberOfConditions - 1) ? ' border-right: 1px solid #737373' : '') -->
            <div v-if="result" color="black">
              {{ result | formatDateShort }}
              {{ result | formatTime }}
            </div>
            <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
          </td>
          <td style="width: 99%"></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "ProcessChainMemberView",
  props: ["processChainId"],
  data: () => ({
    members: [],
    numberOfConditions: 0,
    stepNames: []
  }),
  filters: {
    formatDateShort: function(value) {
      if (value) {
        return new Date(value).toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric"
        });
      }
      return undefined;
    },
    formatTime: function(value) {
      if (value) {
        return new Date(value).toLocaleTimeString("de-de").substring(0, 5) + " Uhr";
      }
      return undefined;
    }
  },
  mounted() {
    this.$store.dispatch("getRequest", ["process-chains/" + this.processChainId + "/member-view", this.fetchMemberViewCallback]);
  },
  methods: {
    fetchMemberViewCallback(data) {
      let entries = data["entries"];
      this.members = entries;
      if (entries.length > 0) {
        this.numberOfConditions = entries[0]["conditionResults"].length;
      }
      this.stepNames = data["stepNames"];
    }
  }
};
</script>

<style scoped>

</style>